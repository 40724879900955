import { QuestQueryParamsEnum, SocialAccountQueryParamsEnum } from '../types/enums';
import { deleteUrlParam } from '@/js/helpers/urlUtils';
import { showToast } from '@/js/helpers/toastHelpers';
import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
import { SocialNetworkEnum } from '../../dashboard/types/enums';
import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
import { dashboardRoutesPaths } from '../../dashboard/constants/dashboardRoutesPaths';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { useQuestRoundDataStore } from '@/js/store/quests/game/modules/QuestRoundDataStore';
import { getTrans } from '@/js/helpers/getTrans';

export const deleteAuthTypeQueryParam = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const authType = queryParams.get(QuestQueryParamsEnum.TYPE);

    if (authType) {
        deleteUrlParam(QuestQueryParamsEnum.TYPE, queryParams);
    }
};

export const handleSocialAccountQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const socialConnectError = queryParams.get(SocialAccountQueryParamsEnum.ERROR);
    const isSocialConnected = queryParams.get(SocialAccountQueryParamsEnum.SOCIAL_CONNECTED);
    const isSocialAccountPrimary = queryParams.get(SocialAccountQueryParamsEnum.IS_PRIMARY);
    const socialNetwork = queryParams.get(SocialAccountQueryParamsEnum.SOCIAL_NETWORK);

    if (socialConnectError) {
        const modalStore = useModalStore();
        modalStore.setSocialAccountErrorModal(true);

        return;
    }

    if (isSocialConnected) {
        deleteUrlParam(SocialAccountQueryParamsEnum.SOCIAL_CONNECTED, queryParams);
        deleteUrlParam(SocialAccountQueryParamsEnum.IS_PRIMARY, queryParams);

        showToast({
            type: 'success',
            message: getTrans('userSocialConnected', 'Your new social account connected successfully.'),
        });

        if (isSocialAccountPrimary === 'false') {
            const path = window.location.pathname;
            const modalStore = useModalStore();

            if (path === dashboardRoutesPaths.SETTINGS_ROUTE_PATH) {
                const userStore = useUserStore();

                userStore.selectedSocialAccount = {
                    user_name: isSocialConnected,
                    social_network: socialNetwork as SocialNetworkEnum,
                    is_primary: false,
                };

                modalStore.setConnectedSocialAccountModal(true);
            }

            if (path === questsRoutes.MISSION_ROUTE) {
                const questRoundDataStore = useQuestRoundDataStore();

                questRoundDataStore.userProfile.selectedSocialAccount = {
                    user_name: isSocialConnected,
                    social_network: socialNetwork as SocialNetworkEnum,
                    is_primary: false,
                };

                modalStore.setConnectedSocialAccountModal(true);
            }
        }
    }
};
